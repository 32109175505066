import React, { Component } from "react"

class Radio extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSelected: props.isSelected === props.id,
    }
    this.onChange = this.onChange.bind(this)
  }
  componentWillReceiveProps(newProps) {
    this.setState({ isSelected: newProps.isSelected === this.props.id })
  }
  onChange(e) {
    this.props.onChangeCallback(e.currentTarget.id)
  }
  render() {
    const { name, id, label } = this.props
    return (
      <div className="row">
        <div className={"radio " + (this.state.isSelected ? "selected" : null)}>
          <input type="radio" id={id} onChange={this.onChange} name={name} />
        </div>
        <label htmlFor={id}>{label}</label>
      </div>
    )
  }
}
export default Radio

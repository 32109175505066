import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Dropdown from "../components/dropdown"
import Radio from "../components/radio"
import Message from "../components/message"

class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: this.getQueryString("email"),
      radioSelected: ""
    }
    this.getQueryString = this.getQueryString.bind(this)
    this.radioSelected = this.radioSelected.bind(this)
  }
  getQueryString(key) {
    var query =
      typeof window !== "undefined" &&
      window.location.search.substr(1).split("&")
    while (query.length) {
      var string = query.pop().split("=")
      if (string[0] === key) {
        return string[1]
      }
    }
  }
  radioSelected(selected) {
    this.setState({ radioSelected: selected })
  }
  render() {
    let visible = 'formBlock';
    visible += this.state.radioSelected === 'yes' ? '' : ' hidden';
    return (
      <Layout>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
        <main>
          <h1>CONVIDADOS RSVP</h1>
          {this.getQueryString("response") && (
            <Message
              type={this.getQueryString("response") !== "true" ? "error" : ""}
              message={
                this.getQueryString("response") === "true"
                  ? false
                  : decodeURIComponent(this.getQueryString("response"))
              }
            />
          )}
          {this.getQueryString("response") !== "true" && (
            <div className="box">
              <h2 className="title">Vão juntar-se a nós?</h2>
              <p>
                Não sabemos se o Marcelo vem, mas vocês são mais importantes.
              </p>
              <i />
              <p>Por favor, confirmar até ao dia 20 de junho.</p>
              <form method="post" action="/index.php">
                <fieldset>
                  <input
                    placeholder="Nome"
                    name="name"
                    type="text"
                  />
                  <input
                    placeholder="E-mail"
                    name="email"
                    id="email"
                    type="text"
                    required
                    style={{ marginBottom: 30 }}
                  />
                  <Radio
                    name="response"
                    label="Sim, lá estaremos"
                    id="yes"
                    isSelected={this.state.radioSelected}
                    onChangeCallback={this.radioSelected}
                  />
                  <Radio
                    name="response"
                    label="Desculpem, não vamos poder ir"
                    id="no"
                    isSelected={this.state.radioSelected}
                    onChangeCallback={this.radioSelected}
                  />
                  <Dropdown
                    name="adults"
                    classes="col-2"
                    options={["Adultos", 1, 2, 3, 4]}
                  />
                  <Dropdown
                    name="children"
                    classes="col-2"
                    options={["Crianças (3 a 8 anos)", 1, 2, 3, 4]}
                  />
                  <p className="left-align">
                    Completar a frase: <em>“A Bárbara e o João...”</em>
                  </p>
                  <textarea name="phrase" />
                </fieldset>
                <div className={visible}>
                  <h2 className="title">E porque a festa é para todos...</h2>
                  <p>Por favor completar.</p>
                  <fieldset>
                    <Dropdown
                      name="food"
                      options={[
                        "Refeição preferida",
                        "Cachaço de Porco com Puré Maçã Reineta",
                        "Cabrito no forno a montanhês.",
                      ]}
                    />
                    <input
                      placeholder="Sobremesa preferida"
                      name="desert"
                      type="text"
                    />
                    <input
                      placeholder="Não gosto de comer"
                      name="nofood"
                      type="text"
                    />
                    <input
                      placeholder="Alergias"
                      name="allergies"
                      type="text"
                    />
                    <input
                      placeholder="A festa começa com"
                      name="partystarts"
                      type="text"
                    />
                    <input
                      placeholder="Num casamento não pode faltar"
                      name="cantmiss"
                      type="text"
                    />
                    <input
                      placeholder="Música preferida para dançar"
                      name="favoritemusic"
                      type="text"
                    />
                    <input
                      placeholder="Palavra preferida"
                      name="favoriteword"
                      type="text"
                    />
                  </fieldset>
                </div>
                <input type="submit" value="Submeter" name="rsvp" />
              </form>
            </div>
          )}
        </main>
      </Layout>
    )
  }
}

export default IndexPage

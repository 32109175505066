import PropTypes from "prop-types"
import React from "react"

const Message = ({ type, message }) => (
  <p className={"response " + type}>
    {!message ? "A sua resposta foi registada com sucesso." : message}
  </p>
)

Message.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
}

Message.defaultProps = {
  type: ``,
  message: "",
}

export default Message

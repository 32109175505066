import React, { Component } from "react"

class Dropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      label: this.props.options[0],
    }
  }
  onChange(e) {
    this.setState({ label: e.currentTarget.value })
  }
  render() {
    const { name, options, classes } = this.props
    const { label } = this.state
    return (
      <div className={"select " + classes}>
        <label htmlFor={name}>{label}</label>
        <select name={name} id={name} onChange={this.onChange.bind(this)}>
          {options.map((v, i) => (
            <option value={v} key={i}>
              {v}
            </option>
          ))}
        </select>
      </div>
    )
  }
}
export default Dropdown
